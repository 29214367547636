<template>
    <div class="w-screen bg-green-900 p-2 text-left text-white text-3xl ">
        System Architecture
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-4 ">
        <div class="col-span-3 " style="overflow: auto">
            <component v-bind:is="currentSystemDiagram" ref="systemDiagram"
                       :pause-after-stage="pauseAnimationAfterEachStage" :current-case="currentCase"></component>
        </div>
        <div class="px-6 ">
            <div class="font-bold text-2xl mb-2">Controls</div>

            <div class="border bg-white rounded-b  p-4 leading-normal">
                <p class="text-gray-700 text-base text-xl">
                    Level of Details:
                </p>
                <p class="pl-3">
                    <input type="radio" name="lod" @change="setLod(1)" class="form-radio text-pink-500" checked/> Level
                    1 <br/>
                    <input type="radio" name="lod" @change="setLod(2)" class="form-radio text-pink-500"/> Level 2 <br/>
                </p>

                <p class="text-gray-700 text-base text-xl mt-5">
                    Animation:
                </p>
                <p class="pl-3">
                    <input type="checkbox" class="form-checkbox text-pink-500" v-model="pauseAnimationAfterEachStage"/>
                    Pause After each stage
                </p>
            </div>

            <div class="border bg-white rounded-b  p-4 leading-normal mt-4">
                <p class="text-gray-700 text-base text-xl">
                    Scenarios
                </p>
                <p class="pl-3">
                    <input type="radio" v-model="currentCase" value="Valid User" class="form-radio text-pink-500" checked/> Valid User<br/>
                    <input type="radio" v-model="currentCase" value="Invalid User" class="form-radio text-pink-500"/> Invalid User <br/>
                </p>
            </div>

            <div class="border bg-white rounded-b  p-4 leading-normal mt-4">
                <p class="text-gray-700 text-base text-xl">
                    Send Message (username)
                </p>
                <p class="pl-3">
                    <input v-model="name" class="form-input rounded-md border-gray-300 shadow-sm"><br/>

                    <button @click="sendMessage"
                            class="mt-2 p-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-700  "
                            v-html="buttonText">

                    </button>



                </p>
            </div>
        </div>
    </div>

</template>

<script>
//https://github.com/yvettemuki/typeflow-editor/blob/master/src/graph/index.js
import SystemDiagram from "../components/SystemDiagram";
import SystemDiagramLevel1 from "../components/SystemDiagramLevel1";
import SystemDiagramLevel2 from "../components/SystemDiagramLevel2";
import {shallowRef} from 'vue';

export default {
    components: { SystemDiagramLevel1, SystemDiagram},
    data() {
        return {
            pauseAnimationAfterEachStage: true,
            name: 'jone.doe',
            buttonText: 'Send Message',
            currentSystemDiagram: shallowRef(SystemDiagramLevel1),
            currentCase: "Valid User"
        }
    },
    methods: {
        setLod(lvl) {
            if (lvl === 2)
                this.currentSystemDiagram = shallowRef(SystemDiagramLevel2);
            else
                this.currentSystemDiagram = shallowRef(SystemDiagramLevel1);
        },
        sendMessage() {
            if (this.pauseAnimationAfterEachStage) {
                this.buttonText = 'Continue';
            } else {
                this.buttonText = 'Send Message';
            }

            if(this.name === '')
            {
                this.currentCase = 'Invalid User';
            }

            this.$refs.systemDiagram.sendMessage(this.name);
        }
    }

}
</script>

