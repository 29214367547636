import mx from 'mxgraph';

const mxgraph = mx({
    mxBasePath: '../src'
});

export default {
    data() {
        return {
            graph: {},
            currentStage: 0,
            stopAnimation: false,
        }
    },
    watch: {
        currentCase() {
            this.currentStage = 0;
            this.stopAnimation = true;
            this.graph.getModel().setVisible(this.graph.getModel().getCell("message"), false);
        }
    },
    methods: {
        initGraph() {
            let container = document.getElementById("system-diagram");
            mxgraph.mxEvent.disableContextMenu(container);
            let graph = new mxgraph.mxGraph(container);
            this.graph = graph;
            mxgraph.mxGraph.prototype.ordered = false;
            this.graph.setEnabled(false);
            this.graph.setPanning(false);
            this.graph.setResizeContainer(false);
            this.graph.setHtmlLabels(true);
            this.graph.view.setTranslate(20, 20);

            // this.graph.centerZoom = true;
            this.graph.getStylesheet().getDefaultEdgeStyle()[mxgraph.mxConstants.STYLE_EDGE] = 'orthogonalEdgeStyle';
            this.graph.getStylesheet().getDefaultEdgeStyle()[mxgraph.mxConstants.STYLE_ROUNDED] = true;
            this.graph.getStylesheet().getDefaultVertexStyle()[mxgraph.mxConstants.STYLE_ROUNDED] = true;
            this.graph.getStylesheet().getDefaultVertexStyle()[mxgraph.mxConstants.STYLE_FONTSIZE] = 14;
            this.graph.getStylesheet().getDefaultVertexStyle()[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#fff';
            this.graph.getStylesheet().getDefaultVertexStyle()[mxgraph.mxConstants.STYLE_FONTFAMILY] = 'Source Sans Pro';
            let _this = this;
            this.graph.addListener(mxgraph.mxEvent.CLICK, function (sender, evt) {
                let cell = evt.getProperty('cell');
                if (cell != null && cell.vertex) {
                    _this.$refs.modal.toggleModal(_this.details[cell.getId()]);
                }
            });

            return graph;
        },

        addMessageVertexes(parent) {
            this.graph.insertVertex(parent, "messageContent", '<div class="message-cont">Click Send Message Button to Start</div>', 600, 10, 300, 160, "whiteSpace=wrap;fillColor=none;strokeColor=#ccc;fontSize=12;verticalAlign=top;align=left;arcSize=3");
            let m = this.graph.insertVertex(parent, "message", '', 30, 30, 30, 30, "fillColor=orange;shape=ellipse;aspect=fixed;visible=false;");
            this.graph.getModel().setVisible(m, false);
        },
        setPoint(v, x, y, addX = false, addY = false) {
            let geo = this.graph.getCellGeometry(v);
            geo = geo.clone();
            geo.x = addX ? geo.x + x : x;
            geo.y = addY ? geo.y + y : y;
            this.graph.getModel().setGeometry(v, geo);
        },
        startAnimation(from) {

            let state = this.graph.view.getState(this.graph.getModel().getCell(from));
            state.shape.node.getElementsByTagName('path')[1].setAttribute('class', 'flow');
            let path = state.shape.node.getElementsByTagName('path')[1];
            let i = 0;
            let l = 160;

            let msg = this.graph.getModel().getCell("message");
            this.graph.getModel().setVisible(msg, true);
            let startPoint = path.getPointAtLength(i / l * path.getTotalLength());
            this.setPoint(msg, startPoint.x - 35, startPoint.y - 35);

            let animate = () => {
                let point = path.getPointAtLength(++i / l * path.getTotalLength());
                this.setPoint(msg, point.x - 35, point.y - 35);
                if (i < l) {
                    setTimeout(animate, 10);
                } else {
                    state.shape.node.getElementsByTagName('path')[1].setAttribute('class', '');
                    this.currentStage++;
                    if (!this.pauseAfterStage) {
                        this.startStage(this.currentStage);
                    }
                }
            };

            setTimeout(animate, 100);
        },
        zoomIn() {
            this.graph.zoomIn();
        },
        zoomOut() {
            this.graph.zoomOut();
        }
    }
}