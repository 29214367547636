<template>
    <div id="system-diagram"></div>
    <modal ref="modal"></modal>
</template>

<script>
import gmx from '../graph';
import Modal from "./Modal";

export default {
    name: "SystemDiagramLevel1",
    components: {Modal},
    props: {
        pauseAfterStage: {
            default: true,
            type: Boolean,
        },
        currentCase: {
            default: "Valid User"
        }
    },
    mixins: [gmx],
    data() {
        return {
            username: '',
            stories: {
                "Valid User": [
                    {
                        message: "POST /login HTTP/1.1\n" +
                            "Host: www.example.com\n" +
                            "Content-Type: application/x-www-form-urlencoded\n" +
                            'Name=<span class="text-red-500">%name%</span>',
                        from: "e1"
                    },
                    {
                        message: "GET https://amazoncognito.com/oauth2/authorize?\n" +
                            "response_type=code&\n" +
                            "client_id=ad398u21ijw3s9w3939&\n" +
                            "username=<span class=\"text-red-500\">%name%</span>\n",
                        from: "e2"
                    },
                    {
                        message: "Response: <span class=\"text-green-500\">valid</span>",
                        from: "e3"
                    },
                    {
                        message: "Redirect To: <span class=\"text-green-500\">/home</span>",
                        from: "e4"
                    }
                ],
                "Invalid User": [
                    {
                        message: "POST /login HTTP/1.1\n" +
                            "Host: www.example.com\n" +
                            "Content-Type: application/x-www-form-urlencoded\n" +
                            'Name=<span class="text-red-500">%name%</span>',
                        from: "e1"
                    },
                    {
                        message: "GET https://amazoncognito.com/oauth2/authorize?\n" +
                            "response_type=code&\n" +
                            "client_id=ad398u21ijw3s9w3939&\n" +
                            "username=<span class=\"text-red-500\">%name%</span>\n",
                        from: "e2"
                    },
                    {
                        message: "Response: <span class=\"text-red-500\">invalid</span>",
                        from: "e3"
                    },
                    {
                        message: "Redirect To: <span class=\"text-red-500\">/access-denied</span>",
                        from: "e4"
                    }
                ]
            },
            details: {
                "v1": {
                    about:"The front-end of a website is the part that users interact with",
                    links:[
                      'youtube 1 link',
                      'confluence 2 link',
                      'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                },
                "v2": {
                    about:"the \"backend\" refers to any part of a website or software program that users do not see",
                    links:[
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                },
                "v3": {
                    about:"AWS (Amazon Web Services) is a comprehensive, evolving cloud computing platform provided by Amazon",
                    links:[
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                }
            }
        }
    },

    mounted() {
        let graph = this.initGraph();
        let parent = graph.getDefaultParent();
        graph.getModel().beginUpdate();

        let vertexes = [
            graph.insertVertex(parent, "v1", 'Frontend', 50, 50, 300, 90, "shape=image;fillColor=#5A7283;strokeColor=none;fontColor=#333;fontSize=20;verticalLabelPosition=top;verticalAlign=bottom;image=https://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/device-laptop-icon.png"),
            graph.insertVertex(parent, "v2", 'Server Components', 50, 400, 300, 200, "fillColor=#4C1D95;strokeColor=none;fontColor=#fff;rounded=1;arcSize=3;fontSize=30;fontStyle=0;"),
            graph.insertVertex(parent, "v3", 'AWS Services', 600, 420, 300, 160, "shape=cloud;fillColor=#1E3A8A;strokeColor=white;fontSize=20;"),
        ];

        let edges = [
            graph.insertEdge(parent, "e1", '<span class="edge-lbl">1</span>', vertexes[0], vertexes[1], 'startArrow=oval;endArrow=block;strokeWidth=4;strokeColor=#69b630;', true),
            graph.insertEdge(parent, "e2", '<span class="edge-lbl">2</span>', vertexes[1], vertexes[2], 'startArrow=oval;endArrow=block;strokeColor=#69b630;strokeWidth=4;'),
            graph.insertEdge(parent, "e3", '<span class="edge-lbl">3</span>', vertexes[2], vertexes[1], 'startArrow=oval;endArrow=block;strokeColor=#BE185D;strokeWidth=4;exitX=0;exitY=0.7;entryX=1;entryY=0.66;'),
            graph.insertEdge(parent, "e4", '<span class="edge-lbl">4</span>', vertexes[1], vertexes[0], 'startArrow=oval;endArrow=block;strokeColor=#BE185D;strokeWidth=4;exitX=0.4;exitY=0;entryX=0.4;entryY=1;')
        ];

        this.addMessageVertexes();

        graph.getModel().endUpdate();
    },
    methods: {
        sendMessage(username) {
            this.username = username;
            this.stopAnimation = false;
            this.startStage(this.currentStage);
        },
        startStage(stage) {
            if (this.stopAnimation || stage >= this.stories[this.currentCase].length) {
                this.currentStage = 0;
                return;
            }

            let story = this.stories[this.currentCase][stage];
            let message = story.message.replace("%name%", this.username);
            this.graph.model.setValue(this.graph.getModel().getCell("messageContent"), `<div class="message-cont">${message}</div>`);
            this.startAnimation(story.from);
        }
    }
}
</script>

