<template>
    <div>
        <div v-if="showModal"
             class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-auto my-6 mx-auto max-w-3xl w-2/4">
                <!--content-->
                <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                            <li class="-mb-px mr-2 last:mr-0 flex-auto text-center" v-if="content.about">
                                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                   v-on:click="toggleTabs(1)"
                                   v-bind:class="{'text-pink-600 bg-white': openTab !== 1, 'text-white bg-pink-600': openTab === 1}">
                                    About
                                </a>
                            </li>
                            <li class="-mb-px mr-2 last:mr-0 flex-auto text-center"  v-if="content.links">
                                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                   v-on:click="toggleTabs(2)"
                                   v-bind:class="{'text-pink-600 bg-white': openTab !== 2, 'text-white bg-pink-600': openTab === 2}">
                                    Links
                                </a>
                            </li>
                            <li class="-mb-px mr-2 last:mr-0 flex-auto text-center"  v-if="content.team">
                                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                   v-on:click="toggleTabs(3)"
                                   v-bind:class="{'text-pink-600 bg-white': openTab !== 3, 'text-white bg-pink-600': openTab === 3}">
                                    Team
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto ">
                        <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
                            <p v-html="content.about">
                            </p>
                        </div>
                        <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
                            <a href="#" v-for="(l,i) in content.links" class="block">
                                {{l}}
                            </a>
                        </div>
                        <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
                            <p v-for="(l,i) in content.team">
                                <strong>{{l.name}}</strong> ({{l.email}})
                            </p>
                        </div>
                    </div>
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button" v-on:click="toggleModal()">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    data() {
        return {
            showModal: false,
            openTab: 1,
            content:{}
        }
    },
    methods: {
        toggleModal: function (content) {
            this.openTab =1;
            this.content = content;
            this.showModal = !this.showModal;
        },
        toggleTabs: function (tabNumber) {
            this.openTab = tabNumber
        }
    }
}
</script>