<template>
    <div id="system-diagram">

    </div>
    <button class="btn btn-blue" v-on:click="exportData">Export</button>
    <br/>
    <button class="btn btn-green" v-on:click="move">move</button>
    <br/>
    <code width="500" ref="datahere">ffg</code>
</template>

<script>
//https://github.com/yvettemuki/typeflow-editor/blob/master/src/graph/index.js
//properties https://codepen.io/Gatcha/pen/RoQypM
//https://github.com/jgraph/mxgraph/issues/350
import mx from 'mxgraph';

const mxgraph = mx({
    mxBasePath: '/static/mxgraph'
});

/*
window.mxGraph = mxgraph.mxGraph;
window.mxGraphModel = mxgraph.mxGraphModel;
window.mxEditor = mxgraph.mxEditor;
window.mxGeometry = mxgraph.mxGeometry;
window.mxDefaultKeyHandler = mxgraph.mxDefaultKeyHandler;
window.mxDefaultPopupMenu = mxgraph.mxDefaultPopupMenu;
window.mxStylesheet = mxgraph.mxStylesheet;
window.mxDefaultToolbar = mxgraph.mxDefaultToolbar;
window.mxConnectionHandler = mxgraph.mxConnectionHandler;
window.mxEdgeHandler = mxgraph.mxEdgeHandler;
 */

export default {
    name: "SystemDiagram",
    data() {
        return {
            graph: null,
            vertexes: [],
        }

    },
    mounted() {
        let container = document.getElementById("system-diagram");
        let graph = new mxgraph.mxGraph(container);
        this.graph = graph;
        let parent = graph.getDefaultParent();

        //graph.setEnabled(true);
        //graph.setConnectable(true);
        mxgraph.mxEvent.disableContextMenu(container);
        // Enables rubberband selection


        // mxgraph.mxGraph.prototype.getAllConnectionConstraints = function(terminal, source)
        // {
        //      if (terminal != null && terminal.shape != null)
        //     {
        //         if (terminal.shape.stencil != null)
        //         {
        //             if (terminal.shape.stencil.constraints != null)
        //             {
        //                 return terminal.shape.stencil.constraints;
        //             }
        //         }
        //         else if (terminal.shape.constraints != null)
        //         {
        //             return terminal.shape.constraints;
        //         }
        //     }
        //
        //     return null;
        // };

        // mxgraph.mxShape.prototype.constraints = [new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0.25, 0), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0.5, 0), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0.75, 0), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0, 0.25), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0, 0.5), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0, 0.75), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(1, 0.25), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(1, 0.5), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(1, 0.75), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0.25, 1), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0.5, 1), true),
        //     new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0.75, 1), true)
        //     ];

        mxgraph.mxShape.prototype.getPorts = function () {
            let ports = [];
            ports['w'] = {x: 0, y: 0.5, perimeter: true, constraint: 'west'};
            ports['e'] = {x: 1, y: 0.5, perimeter: true, constraint: 'east'};
            ports['n'] = {x: 0.5, y: 0, perimeter: true, constraint: 'north'};
            ports['s'] = {x: 0.5, y: 1, perimeter: true, constraint: 'south'};
            ports['nw'] = {x: 0, y: 0, perimeter: true, constraint: 'north west'};
            ports['ne'] = {x: 1, y: 0, perimeter: true, constraint: 'north east'};
            ports['sw'] = {x: 0, y: 1, perimeter: true, constraint: 'south west'};
            ports['se'] = {x: 1, y: 1, perimeter: true, constraint: 'south east'};
            return ports;
        };

        mxgraph.mxPolyline.prototype.constraints = null;

        // new mxgraph.mxRubberband(graph);
        // graph.insertVertex(parent, null, 'Hello, World!', 20, 20, 80, 20,
        //     'boxstyle');

        // graph.setConnectable(true);
        //mxConstants.STYLE_ROUNDED

        graph.getStylesheet().getDefaultEdgeStyle()[mxgraph.mxConstants.STYLE_EDGE] = 'orthogonalEdgeStyle';
        graph.getStylesheet().getDefaultEdgeStyle()[mxgraph.mxConstants.STYLE_ROUNDED] = true;
        graph.getStylesheet().getDefaultVertexStyle()[mxgraph.mxConstants.STYLE_ROUNDED] = true;
        graph.getStylesheet().getDefaultVertexStyle()[mxgraph.mxConstants.STYLE_FONTSIZE] = 14;
        graph.getStylesheet().getDefaultVertexStyle()[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#fff';
        graph.getStylesheet().getDefaultVertexStyle()[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#fff';

        graph.connectionHandler.createEdgeState = function (me) {
            let edge = graph.createEdge(null, null, null, null, null);

            return new mxgraph.mxCellState(this.graph.view, edge, this.graph.getCellStyle(edge));
        };
//https://www.iconpacks.net/icons/1/free-laptop-icon-711-thumb.png
        graph.getModel().beginUpdate();
        let vertexes = [
            //graph.insertVertex(parent, null, 'Backend API', 260, 5, 160, 120,"shape=image;fillColor=#5A7283;strokeColor=none;fontColor=#fff;fontSize=14;image=https://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/device-laptop-icon.png"),
            graph.insertVertex(parent, "v1", 'Backend API', 280, 50, 120, 40, "fillColor=#5A7283;strokeColor=none;fontColor=#fff;fontSize=14;"),
            graph.insertVertex(parent, null, 'Devtools', 600, 300, 120, 40, "fillColor=#5A7283;strokeColor=none"),
            graph.insertVertex(parent, null, 'Vue Components', 60, 300, 120, 40, "fillColor=#3AB882;strokeColor=none"),

            graph.insertVertex(parent, null, 'Actions', 300, 180, 80, 80, "shape=ellipse;fillColor=#FCB738;strokeColor=none"),
            graph.insertVertex(parent, null, 'Mutations', 420, 280, 80, 80, "shape=ellipse;fillColor=#DA5961;strokeColor=none"),
            graph.insertVertex(parent, null, 'State', 300, 370, 80, 80, "shape=ellipse;fillColor=#8E7CB9;strokeColor=none"),
            graph.insertVertex(parent, null, 'VueX', 240, 140, 300, 350, "fillColor=none;strokeColor=#3AB882;dashed=1;rounded=1;arcSize=3;verticalLabelPosition=top;verticalAlign=bottom;fontColor=#f00"),
            graph.insertVertex(parent, null, '', 30, 30, 30, 30, "fillColor=orange"),
        ];

        this.vertexes = vertexes;

        //graph.insertEdge(parent, null, 'Connect', vertexes[0], vertexes[3], 'dashed=1,perimeterSpacing=4;strokeWidth=4;STYLE_DASHEDlabelBackgroundColor=white;fontStyle=1');
        let e1 = graph.insertEdge(parent, "e1", null, vertexes[0], vertexes[3], 'dashed=1;startArrow=block;endArrow=block;strokeColor=#B5C7CE');
        let e2 = graph.insertEdge(parent, "e2", null, vertexes[1], vertexes[4], 'dashed=1;startArrow=block;endArrow=block;strokeColor=#B5C7CE');

        let e3 = graph.insertEdge(parent, "e3", null, vertexes[3], vertexes[4], 'startArrow=none;endArrow=block;strokeColor=#8598A3;strokeWidth=2;');
        let e4 = graph.insertEdge(parent, "e4", null, vertexes[2], vertexes[3], 'startArrow=block;endArrow=block;strokeColor=#8598A3;strokeWidth=2;');
        // let e7 = graph.insertEdge(parent, null, null, vertexes[3], vertexes[2], 'startArrow=block;endArrow=block;strokeColor=#8598A3;strokeWidth=2;');
        let e5 = graph.insertEdge(parent, "e5", null, vertexes[2], vertexes[5], 'startArrow=block;endArrow=block;strokeColor=#8598A3;strokeWidth=2;class=e5');
        let e6 = graph.insertEdge(parent, "e6", null, vertexes[4], vertexes[5], 'endArrow=block;strokeColor=#8598A3;strokeWidth=2;exitX=0.5;exitY=1;exitDx=0;exitDy=0;entryX=1;entryY=0.5;entryDx=0;entryDy=0;');

        graph.setEnabled(false);
        graph.orderCells(true, [e1]);

        graph.getModel().endUpdate();


        // graph.getModel().beginUpdate();
        // try {
        //     let geo = graph.getCellGeometry(vertexes[0]);
        //     geo = geo.clone();
        //     geo.x += 180;
        //     //geo.y += 180 ;
        //     graph.getModel().setGeometry(vertexes[0], geo);
        //     // let circleLayout = new mxgraph.mxCircleLayout(graph);
        //     // circleLayout.execute(graph.getDefaultParent());
        // } finally {
        //     let morph = new mxgraph.mxMorphing(graph, 10, 1.2, 100);
        //     morph.addListener(mxgraph.mxEvent.DONE, function () {
        //         graph.getModel().endUpdate();
        //     });
        //
        //     morph.startAnimation();
        // }


        // Adds animation to edge shape and makes "pipe" visible
        let state = graph.view.getState(e1);
        state.shape.node.getElementsByTagName('path')[0].removeAttribute('visibility');
        state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke-width', '6');
        // state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke', 'lightGray');
        state.shape.node.getElementsByTagName('path')[1].setAttribute('class', 'flow');


        // let vertexStyle = 'shape=cylinder;strokeWidth=2;fillColor=#ffffff;strokeColor=black;' +
        //     'gradientColor=#a0a0a0;fontColor=black;fontStyle=1;spacingTop=14;';
        // let v1 = graph.insertVertex(parent, null, 'Pump', 20, 20, 60, 60,vertexStyle);
        // let v2 = graph.insertVertex(parent, null, 'Tank', 200, 150, 60, 60,vertexStyle);
        // let e11 = graph.insertEdge(parent, null, '', v1, v2,
        //     'strokeWidth=3;endArrow=block;endSize=2;endFill=1;strokeColor=black;rounded=1;');
        // e1.geometry.points = [new mxgraph.mxPoint(230, 50)];
        // graph.orderCells(true, [e1]);


        /*var vertexStyle = 'shape=cylinder;strokeWidth=2;fillColor=#ffffff;strokeColor=black;' +
				'gradientColor=#a0a0a0;fontColor=black;fontStyle=1;spacingTop=14;';*/
        // state.shape.node.getElementsByTagName('path')[0].removeAttribute('visibility');
        // state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke-width', '6');
        // state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke', 'lightGray');
        // state.shape.node.getElementsByTagName('path')[1].setAttribute('class', 'flow');

        //exitDy=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0
        // graph.setConnectionConstraint(e6,vertexes[4],true,new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0.5, 1),true));
        // graph.setConnectionConstraint(e6,vertexes[5],false,new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(1,0.5),true));
        //e6.setConnectionConstraint(new mxgraph.mxConnectionConstraint(new mxgraph.mxPoint(0.5, 1),true));


        //e6.geometry.points = [new mxgraph.mxPoint(460, 410)];
        //e6.geometry.offset = new mxgraph.mxPoint(0, 100);
        // e6.geometry.relative = true;
        // e6.connectable = false;
        /*
        view-source:https://jgraph.github.io/mxgraph/javascript/examples/control.html
        graph.centerZoom = false;
        graph.zoomIn();
        graph.zoomOut();
         */
//'defaultEdge;verticalAlign=top;verticalLabelPosition=bottom'
        // e6.geometry.points = [new mxgraph.mxPoint(500, 300)];


        /*
        Animations
        var state = graph.view.getState(e1);
			state.shape.node.getElementsByTagName('path')[0].removeAttribute('visibility');
			state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke-width', '6');
			state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke', 'lightGray');
			state.shape.node.getElementsByTagName('path')[1].setAttribute('class', 'flow');
         */
        /*
        var style = graph.getStylesheet().getDefaultVertexStyle();
				style['fillColor'] = '#FFFFFF';
				style['strokeColor'] = '#000000';
				style['fontColor'] = '#000000';
				style['fontStyle'] = '1';

				style = graph.getStylesheet().getDefaultEdgeStyle();
				style['strokeColor'] = '#000000';
				style['fontColor'] = '#000000';
				style['fontStyle'] = '0';
				style['fontStyle'] = '0';
				style['startSize'] = '8';
				style['endSize'] = '8';
        var v1 = graph.insertVertex(parent, null, 'v1', 20, 20, 80, 30);
					var v2 = graph.insertVertex(parent, null, 'v2', 440, 20, 80, 30);
					var e1 = graph.insertEdge(parent, null, '', v1, v2, 'dashed=1;'+
						'startArrow=oval;endArrow=block;sourcePerimeterSpacing=4;startFill=0;endFill=0;');
					var e11 = graph.insertVertex(e1, null, 'Label', 0, 0, 20, 14,
						'shape=message;labelBackgroundColor=#ffffff;labelPosition=left;spacingRight=2;align=right;fontStyle=0;');
					e11.geometry.offset = new mxPoint(-10, -7);
					e11.geometry.relative = true;
					e11.connectable = false;

					var v3 = graph.insertVertex(parent, null, 'v3', 20, 120, 80, 30);
					var v4 = graph.insertVertex(parent, null, 'v4', 440, 120, 80, 30);
					var e2 = graph.insertEdge(parent, null, 'Label', v3, v4,
						'startArrow=dash;startSize=12;endArrow=block;labelBackgroundColor=#FFFFFF;');

					var v5 = graph.insertVertex(parent, null, 'v5', 40, 220, 40, 40, 'shape=ellipse;perimeter=ellipsePerimeter;');
					var v6 = graph.insertVertex(parent, null, 'v6', 460, 220, 40, 40, 'shape=doubleEllipse;perimeter=ellipsePerimeter;');
					var e3 = graph.insertEdge(parent, null, 'Link', v5, v6,
						'shape=link;labelBackgroundColor=#FFFFFF;');
						strokeWidth=3;endArrow=block;endSize=2;endFill=1;strokeColor=black;rounded=1;
         */

//'dashed=1;startArrow=block;endArrow=block;sourcePerimeterSpacing=4;startFill=0;endFill=0;'
//STYLE_DASHED = "dashed"
        //   let v1 = graph.insertVertex(parent, null, 'Backend API', 20, 20, 80, 30);
        //   let v2 = graph.insertVertex(parent, null, 'World!', 200, 200, 90, 90,"shape=ellipse");
        // //  let e1 = graph.insertEdge(parent, null, '', v1, v2);
        //   let e1 = graph.insertEdge(parent, null, 'Connect', v1, v2, 'perimeterSpacing=4;strokeWidth=4;labelBackgroundColor=white;fontStyle=1');


        // graph.addListener(mxgraph.mxEvent.CLICK, function(sender, evt)
        // {
        //     var cell = evt.getProperty('cell');
        //
        //     if (cell != null)
        //     {
        //         alert("hi");
        //     }
        // });
        /*
                   Object v1 = graph.insertVertex(parent, null, "source", 20, 20,80, 30,"shape=ellipse");
           Object v2 = graph.insertVertex(parent, null, "destination", 200, 20,80, 30,"image");
           graph.insertEdge(parent, null, "", v1,v2,"startArrow=none;endArrow=diamond;strokeWidth=4;strokeColor=#66FF00");

         */


    },
    methods: {
        exportData(event) {
            let enc = new mxgraph.mxCodec(mxgraph.mxUtils.createXmlDocument());
            let node = enc.encode(this.graph.getModel());
            let xml = mxgraph.mxUtils.getXml(node);

            this.$refs.datahere.innerText = (xml);
            console.log(xml);


        },
        setPoint(v, x, y, addX = false, addY = false) {
            let geo = this.graph.getCellGeometry(v);
            geo = geo.clone();
            geo.x = addX ? geo.x + x : x;
            geo.y = addY ? geo.y + y : y;
            this.graph.getModel().setGeometry(v, geo);
        },
        move() {
            this.graph.getModel().beginUpdate();
            let t = 0;
            let edge = this.graph.getModel().getCell("e5");//this.graph.getCellGeometry(this.vertexes[6]);
            let b = this.graph.getView().getPerimeterBounds(this.graph.getView().getState(edge), null, false);
            // let sp =this.graph.getView().getTerminalPort  (this.graph.getView().getState(v6Geo));

            // let s =
            console.log(b);
            //console.log(sp);
            //this.graph.getView().getPerimeterBounds()
            let start = edge.source.geometry;
            let end = edge.target.geometry;

            console.log(edge.source.geometry);
            console.log(edge.target.geometry);


            let dividerX = end.x - start.x;
            let dividerY = end.y - start.y;

            let state = this.graph.view.getState(this.graph.getModel().getCell("e5"));
            state.shape.node.getElementsByTagName('path')[1].setAttribute('class', 'flow');
            let path = state.shape.node.getElementsByTagName('path')[0];
            let i = 0;
            let l = 160;

            let pp = path.getPointAtLength(i / l * path.getTotalLength());
            console.log(path.getTotalLength());
            //this.setPoint(this.vertexes[7],start.x + (start.width /2) - 15,start.y + start.height / 2 - 15);
            this.setPoint(this.vertexes[7], pp.x, pp.y);
            console.log("Test");
            let foo = () => {
                pp = path.getPointAtLength(++i / l * path.getTotalLength());
                this.setPoint(this.vertexes[7], pp.x, pp.y+10);
                //this.setPoint(this.vertexes[7],10,b.y,false,true);
                // let geo = this.graph.getCellGeometry(this.vertexes[7]);
                // geo = geo.clone();
                // geo.x += 10;
                // geo.y = b.y;
                // this.graph.getModel().setGeometry(this.vertexes[7], geo);
                if (i < l) {
                    setTimeout(foo, 10);
                }
                else
                {
                    state.shape.node.getElementsByTagName('path')[1].setAttribute('class', '');
                }
            };

            setTimeout(foo, 100);

            this.graph.getModel().endUpdate();
        }
    }
}
</script>

<style scoped>

#system-diagram {
    height: 600px;
    border: 1px solid red;
}

.flow {
    stroke-dasharray: 8;
    animation: dash 0.5s linear;
    animation-iteration-count: infinite;
}

@keyframes dash {
    to {
        stroke-dashoffset: -16;
    }
}
</style>
