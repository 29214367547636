<template>
    <div id="system-diagram"></div>
    <modal ref="modal"></modal>
</template>

<script>
import gmx from '../graph';
import Modal from "./Modal";

export default {
    name: "SystemDiagramLevel2",
    components: {Modal},
    props: {
        pauseAfterStage: {
            default: true,
            type: Boolean,
        },
        currentCase: {
            default: "Valid User"
        }
    },
    mixins: [gmx],
    data() {
        return {
            username: '',
            stories: {
                "Valid User": [
                    {
                        message: "POST /login HTTP/1.1\n" +
                            "Host: www.example.com\n" +
                            "Content-Type: application/x-www-form-urlencoded\n" +
                            'Name=<span class="text-red-500">%name%</span>',
                        from: "e1"
                    },
                    {
                        message: "GET https://amazoncognito.com/oauth2/authorize?\n" +
                            "response_type=code&\n" +
                            "client_id=ad398u21ijw3s9w3939&\n" +
                            "username=<span class=\"text-red-500\">%name%</span>\n",
                        from: "e2"
                    },
                    {
                        message: "Response: <span class=\"text-green-500\">valid</span>",
                        from: "e3"
                    },
                    {
                        message: "Check if user information exists in the cache",
                        from: "e4"
                    },
                    {
                        message: "<span class=\"text-red-500\">no data</span>",
                        from: "e5"
                    },
                    {
                        message: "Retrieve user data from database",
                        from: "e6"
                    },
                    {
                        message: "<span class=\"text-green-500\">name:%name%;email:%name%@example.com;</span>",
                        from: "e7"
                    },
                    {
                        message: "save data to the cache\n<span class=\"text-green-500\">name:%name%;email:%name%@example.com;</span>",
                        from: "e4"
                    },
                    {
                        message: "Redirect To: <span class=\"text-green-500\">/home</span>\n" +
                         "User: <span class=\"text-green-500\">name:%name%;email:%name%@example.com;</span>",
                        from: "e8"
                    }
                ],
                "Invalid User": [
                    {
                        message: "POST /login HTTP/1.1\n" +
                            "Host: www.example.com\n" +
                            "Content-Type: application/x-www-form-urlencoded\n" +
                            'Name=<span class="text-red-500">%name%</span>',
                        from: "e1"
                    },
                    {
                        message: "GET https://amazoncognito.com/oauth2/authorize?\n" +
                            "response_type=code&\n" +
                            "client_id=ad398u21ijw3s9w3939&\n" +
                            "username=<span class=\"text-red-500\">%name%</span>\n",
                        from: "e2"
                    },
                    {
                        message: "Response: <span class=\"text-red-500\">invalid</span>",
                        from: "e3"
                    },
                    {
                        message: "Redirect To: <span class=\"text-green-500\">/home</span>",
                        from: "e8"
                    }
                ]
            },
            details: {
                "frontend": {
                    about: "The front-end of a website is the part that users interact with",
                    links: [
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                },
                "server": {
                    about: "the \"backend\" refers to any part of a website or software program that users do not see",
                    links: [
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                },

                "server-code": {
                    about: "the \"backend\" refers to any part of a website or software program that users do not see",
                    links: [
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                },
                "server-database": {
                    about: "the \"backend\" refers to any part of a website or software program that users do not see",
                    links: [
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                },
                "server-cache": {
                    about: "AWS (Amazon Web Services) is a comprehensive, evolving cloud computing platform provided by Amazon",
                    links: [
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                },
                "aws": {
                    about: "AWS (Amazon Web Services) is a comprehensive, evolving cloud computing platform provided by Amazon",
                    links: [
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                },
                "aws-cognito": {
                    about: "AWS (Amazon Web Services) is a comprehensive, evolving cloud computing platform provided by Amazon",
                    links: [
                        'youtube 1 link',
                        'confluence 2 link',
                        'confluence 3 link',
                    ],
                    team: [
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        },
                        {
                            email: 'ali@almahari.com',
                            name: 'Ali Almahari'
                        }
                    ]
                }
            }
        }
    },

    mounted() {
        let graph = this.initGraph();
        let parent = graph.getDefaultParent();
        graph.getModel().beginUpdate();

        let vertexes = [
            graph.insertVertex(parent, "frontend", 'Frontend', 100, 20, 180, 90, "shape=image;fillColor=#5A7283;strokeColor=none;fontColor=#333;fontSize=20;verticalLabelPosition=top;verticalAlign=bottom;image=https://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/device-laptop-icon.png"),
            graph.insertVertex(parent, "server", 'Server Components', 10, 300, 350, 320, "dashed=1;fillColor=none;strokeColor=#4C1D95;fontColor=#fff;rounded=1;arcSize=3;fontSize=30;fontStyle=0;verticalLabelPosition=bottom;verticalAlign=top;fontColor=#4C1D95"),
            graph.insertVertex(parent, "aws", 'AWS Services', 500, 230, 450, 300, "shape=cloud;dashed=1;fillColor=none;strokeColor=#1E3A8A;fontSize=20;verticalLabelPosition=top;verticalAlign=bottom;fontColor=#1E3A8A"),

            graph.insertVertex(parent, "server-code", "Auth Controller", 120, 320, 140, 80, "fillColor=#F472B6;strokeColor=#DB2777;fontColor=#fff;rounded=1;arcSize=3;fontSize=14;"),
            graph.insertVertex(parent, "server-database", "Database", 50, 500, 70, 80, "shape=cylinder;fillColor=#FBBF24;strokeColor=#D97706;fontColor=#fff;rounded=1;arcSize=3;fontSize=14;"),
            graph.insertVertex(parent, "server-cache", "Cache", 250, 500, 70, 80, "shape=swimlane;fillColor=#60A5FA;strokeColor=#2563EB;fontColor=#fff;rounded=1;arcSize=3;fontSize=14;"),

            graph.insertVertex(parent, "aws-cognito", "Cognito", 680, 320, 100, 100, "shape=ellipse;fillColor=#34D399;aspect=fixed;strokeColor=#059669;fontColor=#fff;rounded=1;arcSize=3;fontSize=14;"),
        ];


        let edges = [
            graph.insertEdge(parent, "e1", '<span class="edge-lbl">1</span>', vertexes[0], vertexes[3], 'startArrow=oval;endArrow=block;strokeWidth=4;strokeColor=#69b630;'),
            graph.insertEdge(parent, "e2", '<span class="edge-lbl">2</span>', vertexes[3], vertexes[6], 'startArrow=oval;endArrow=block;strokeColor=#69b630;strokeWidth=4;exitX=1;exitY=0.3;entryX=0;entryY=0.3;'),
            graph.insertEdge(parent, "e3", '<span class="edge-lbl">3</span>', vertexes[6], vertexes[3], 'startArrow=oval;endArrow=block;strokeColor=#BE185D;strokeWidth=4;exitX=0;exitY=0.7;entryX=1;entryY=0.7;'),

            graph.insertEdge(parent, "e4", '<span class="edge-lbl">4</span>', vertexes[3], vertexes[5], 'startArrow=oval;endArrow=block;strokeColor=#69b630;strokeWidth=4;exitX=0.7;exitY=1;entryX=0.5;entryY=0;'),
            graph.insertEdge(parent, "e5", '<span class="edge-lbl">5</span>', vertexes[5], vertexes[3], 'startArrow=oval;endArrow=block;strokeColor=#BE185D;strokeWidth=4;exitX=0;exitY=0.5;entryX=0.5;entryY=1;'),

            graph.insertEdge(parent, "e6", '<span class="edge-lbl">6</span>', vertexes[3], vertexes[4], 'startArrow=oval;endArrow=block;strokeColor=#69b630;strokeWidth=4;exitX=0.3;exitY=1;entryX=0.5;entryY=0;'),
            graph.insertEdge(parent, "e7", '<span class="edge-lbl">7</span>', vertexes[4], vertexes[3], 'startArrow=oval;endArrow=block;strokeColor=#BE185D;strokeWidth=4;exitX=0;exitY=0.5;entryX=0;entryY=0.5;'),

            graph.insertEdge(parent, "e8", '<span class="edge-lbl">8</span>', vertexes[3], vertexes[0], 'startArrow=oval;endArrow=block;strokeColor=#BE185D;strokeWidth=4;exitX=0.3;exitY=0;entryX=0.35;entryY=1;')
        ];

        this.addMessageVertexes();

        graph.getModel().endUpdate();
    },
    methods: {
        sendMessage(username) {
            this.username = username;
            this.stopAnimation = false;
            this.startStage(this.currentStage);
        },
        startStage(stage) {
            if (this.stopAnimation || stage >= this.stories[this.currentCase].length) {
                this.currentStage = 0;
                return;
            }

            let story = this.stories[this.currentCase][stage];
            let message = story.message.replace(/%name%/g, this.username);
            this.graph.model.setValue(this.graph.getModel().getCell("messageContent"), `<div class="message-cont">${message}</div>`);
            this.startAnimation(story.from);
        }
    }
}
</script>

